<template>
    <div class="user-data-list">
        <el-button icon="el-icon-arrow-left" @click="goBack">返回</el-button>
        <div class="tab-wrapper">
            <el-tabs v-model="activeTab" type="card" @tab-click="handleClick">
                <el-tab-pane label="创建的" name="Jobs">创建的</el-tab-pane>
                <el-tab-pane label="抢单的" name="Orders">抢单的</el-tab-pane>
            </el-tabs>
            <div class="tab-right">
                <el-dropdown @command="changeSortType">
                    <span class="el-dropdown-link">
                        {{sortList[sortType]}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(sort, index) in sortList" :key="index" :command="index">{{sort}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-input
                    placeholder="搜索职位名称、客户"
                    v-model="keyword"
                >
                    <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            sortList: ['按更新时间排序', '按发布时间排序', '按佣金金额排序', '按佣金比例排序'],
            sortType: 0,
            keyword: '',
            activeTab: 'Orders'
        }
    },
    mounted() {
        this.getJobList();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        changeSortType(type) {
            this.sortType = type;
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        getJobList() {

        },
        selectJob(job) {
            this.job = job;
        },
        selectTimeLimit(timeLimit) {
            this.timeLimit = timeLimit;
        }
    }
}
</script>

<style lang="scss" scoped>
.user-data-list {
    .tab-wrapper {
        position: relative;
        margin-top: 25px;

        /deep/ .el-tabs {
            .el-tabs__item {
                min-width: 130px;
                text-align: center;
            }
        }

        .tab-right {
            position: absolute;
            top: -6px;
            right: 0;
            display: flex;

            .el-dropdown {
                flex-shrink: 0;
                line-height: 40px;
                margin-right: 12px;
                color: #b9b9b9;
            }

            .el-input-group__append .el-button {
                height: 100%;
            }
        }

    }
}
</style>